import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Support from "../components/Support";
import Article from "../components/Article";
import Contact from "../components/Contact";
import Headline from "../components/Article/Headline";
import Seo from "../components/Seo";
import Categories from "../components/Category";
//import { cryptr } from "../../config";
import { connect } from 'react-redux';

const Cryptr = require('cryptr');
//const cryptr = new Cryptr('myTotalySecretKey123');
const cryptr = new Cryptr('singaporege2020miaomiaomiao');
// mark: a1225d5e4c91efe61e119b3bf213bd35c241d24b
// markpro: 226771a0af7f653d1d2f523dd09c4b8b4194a14f7bd3c0
//whatifthisnots9: 8255dec5c429d9f8799b16d468fc2d8ccd28ae770dc197cabae2857f24dc0b

class SecurityPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          category: [],
          selectedcat: '',
          first: '',
          second: '',
          key: '',
          cryptr: cryptr          
        }
        this.SelectCategory = this.SelectCategory.bind(this)
    }
    onLogin(e) {
      e.preventDefault()
      this.props.dispatch({
        type: 'BUTTON',
        value: true
      })
    }

    SelectCategory(key) {
    }

    componentDidMount() {
        let category = [];
        category.push(
          {
            'title': 'Support'
          }
        )
        category.push(
          {
            'title': 'News'
          }
        )
        this.setState({
          category: category,
          selectedcat: 'Support'
        })
    }
    changeValue(e) {
      e.preventDefault()

      this.setState({
        first: e.target.value
      })
      let cry = this.state.cryptr
      const encryptedString = cry.encrypt(e.target.value);
      this.setState({
        second: encryptedString
      })
    }
    changeValuekey(e) {
      e.preventDefault()
      this.setState({
        key: e.target.value,
        cryptr: new Cryptr(e.target.value)
      })
    }    
    changeValue2(e) {

    }
    render() {
        const {
            data: {
                supports: { edges: supports = [] }
            }
        } = this.props
        return(
            <React.Fragment>
                <div className="search_container">
                  <div className="s01">
                    <form>
                    <div className="inner-form">  
                      {
                        this.props.button === false ?
                        <button className="input-field first-wrap btn btn-default loginbutton" onClick={(e) => this.onLogin(e)}>Login</button>
                        :<div></div>
                      }
                      {
                        this.props.loginStatus === 'Logout' ?
                        <div className="input-field first-wrap">
                          <input id="search" type="text" value = {this.state.key}
                            onChange = {(e) => this.changeValuekey(e)} placeholder = "key"
                          />
                        </div>:<div></div>
                      }
                      </div>
                      {
                        this.props.loginStatus === 'Logout' ?
                          <div className="inner-form">
                            <div className="input-field first-wrap">
                              <input id="search" type="text" value = {this.state.first}
                                onChange = {(e) => this.changeValue(e)} placeholder = "plain"
                              />
                            </div>
                            <div className="input-field first-wrap">
                              <input id="search" type="text" value = {this.state.second}
                                onChange = {(e) => this.changeValue2(e)} placeholder = "encryption"
                              />
                            </div>
                          </div>
                        :
                        <div></div>
                      }                      
                    </form>
                  </div>
                </div>
                {/* --- STYLES --- */}
                <style jsx>{`
                  .search_container {
                    padding: 10px;
                    max-width: 65em;
                    margin: 0 auto !important;
                    margin-bottom: 40px !important;
                    .Search_content {
                      .search_list {
                        margin-left: 40px;
                        li {
                          margin-bottom: 15px;
                        }
                      }
                      h1 {
                        margin: 0.5em 0 2em 0.3em;
                        font-size: 1.2em;
                        color: #0098e4;
                        display: block;
                      }
                    }
                  }
                  .icon {
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 20px;
                  }
                  .icon :global(svg) {
                    height: 30px;
                  }
                `}</style>
            </React.Fragment>
        )
    }
}

SecurityPage.propTypes = {
    data: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    loginStatus: state.loginReducer.login,
    button: state.loginReducer.button
  }
}

export default connect(mapStateToProps)(SecurityPage);

//eslint-disable-next-line no-undef
export const query = graphql`
  query SecurityQuery {
    supports: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//support/[0-9]+.*--/" } }
        sort: { fields: [fields___prefix], order: DESC }
      ) {
        edges {
          node {
            excerpt
            fields {
              slug
              prefix
            }
            frontmatter {
              title
              category
              author
              cover {
                children {
                  ... on ImageSharp {
                    fluid(maxWidth: 800, maxHeight: 360) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
`;